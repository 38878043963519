import clsx from 'clsx'
import React from 'react'
import type { LinkResult } from '../app/data/content/LinkFragment'
import styles from '../app/styles/StickyHeader.module.sass'
import { Button } from './Button'
import { Link } from './Link'
export function StickyHeader(props: {
	headline?: string
	desc?: string
	link?: LinkResult
	email?: boolean
	className?: string
}) {
	const [phone, setPhone] = React.useState<string>()
	const [formSent, setFormSent] = React.useState(false)

	const submitForm = async () => {
		if (phone) {
			const payload = {
				phone: phone,
				url: window.location.href,
			}

			const res = await fetch('/api/callRequest', {
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
			})

			const result = await res.json()

			if (result.status === 'ok') {
				setFormSent(true)
			} else {
				setFormSent(false)
			}
		}
	}

	return (
		<header className={clsx(styles.wrapper, props.className && styles[props.className])}>
			<div className={styles.inner}>
				<h1 className={styles.headline}>{props.headline}</h1>
				{props.desc && <article className={styles.description}>{props.desc}</article>}
			</div>
			{props.link && <Link className="viewStickyHeader" isBtn link={props.link} />}
			{props.email && (
				<div className={styles.email}>
					<article>Nebo</article>
					<div className={styles.emailInner}>
						{!formSent && (
							<>
								<input
									type="tel"
									id="phone"
									name="phone"
									placeholder="Vaše telefonní číslo"
									required
									onChange={(e) => {
										setPhone(e.target.value)
									}}></input>

								<Button
									onClick={() => {
										if (phone && !formSent) {
											submitForm()
										} else {
										}
									}}
									className="viewBlack">
									Ozvěte se mi
								</Button>
							</>
						)}
					</div>
					{formSent ? (
						<article>Děkujeme za zájem. Hned jakmile to bude možné Vám zavoláme!</article>
					) : (
						<article>Zanechte nám zde na sebe kontakt a my se Vám ozveme.</article>
					)}
				</div>
			)}
		</header>
	)
}
