import clsx from 'clsx'
import React, { RefObject } from 'react'
import styles from '../app/styles/Modal.module.sass'
import { ModalContext } from '../utils/ModalContext'
import { Cross } from './Cross'

export function Modal() {
	const modalContext = React.useContext(ModalContext)

	const ref = React.useRef() as RefObject<HTMLDivElement>

	React.useEffect(() => {
		if (modalContext?.modalOpen) {
			const handleClickOutside = (event: MouseEvent) => {
				const clickedEl = event.target as Node | null
				if (ref.current && !ref.current.contains(clickedEl)) {
					modalContext.modalOpenFunction(false)
					modalContext?.modalContentFunction(<></>)
				}
			}
			document.addEventListener('mousedown', handleClickOutside)

			return () => {
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}
	}, [ref, modalContext])

	return (
		<div className={clsx(styles.wrapper, modalContext?.modalOpen && styles.visible)}>
			<div className={styles.inner} ref={ref}>
				<button
					className={styles.close}
					onClick={() => {
						modalContext?.modalOpenFunction(false)
						modalContext?.modalContentFunction(<></>)
					}}>
					<Cross />
				</button>
				{modalContext?.modalContent}
			</div>
		</div>
	)
}
