import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { FAQResult } from '../app/data/content/FAQFragment'
import styles from '../app/styles/FAQ.module.sass'
import { Container } from './Container'
export function FAQ(props: { items: FAQResult[]; title?: string }) {
	const [activeQuestion, setActiveQuestion] = React.useState<null | number>(null)
	return (
		<section className={clsx(styles.wrapper, 'section')}>
			<Container>
				<div className={styles.inner}>
					{props.title ? (
						<h2 className={styles.headline}>{props.title}</h2>
					) : (
						<h2 className={styles.headline}>Často kladené dotazy</h2>
					)}
					<ul className={styles.list}>
						{props.items &&
							props.items.map((item, index) => (
								<li
									key={index}
									className={clsx(styles.item, activeQuestion === index && styles.active)}>
									<h3>
										<button
											onClick={() => {
												if (activeQuestion === index) {
													setActiveQuestion(null)
												} else {
													setActiveQuestion(index)
												}
											}}>
											{item.localesByLocale?.question}
											<span></span>
										</button>
									</h3>
									<article>
										{item.localesByLocale?.answer && (
											<RichTextRenderer source={item.localesByLocale?.answer} />
										)}
									</article>
								</li>
							))}
					</ul>
				</div>
			</Container>
		</section>
	)
}
