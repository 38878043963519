import Link from 'next/link'
import styles from '../app/styles/TopBar.module.sass'

export function TopBar() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<article>
					Darujte neon na míru se slevou <b>30%</b> po zadání kódu <b>VANOCE</b> - platí pro prvních
					20 objednávek v našem{' '}
					<Link href="/konfigurator" style={{ textDecoration: 'underline' }}>
						konfigurátoru
					</Link>{' '}
					🎁
				</article>
			</div>
		</div>
	)
}
